$drawer-width: 240px;

.dashboard__drawer {
  min-width: $drawer-width;

  .MuiDrawer-paper {
    min-width: $drawer-width;
  }

  .Mui-disabled {
    display: none;
  }

  &--parent-link {
    .MuiCollapse-container {
      padding-left: 20px;
    }
  }
}
