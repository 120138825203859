
.auth__content {
  display: flex;
  justify-content: center;
  margin-top: 33vh;
}

.login {
  &__error-message {
    color: $red;
  }
}
