.center {
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bottom {
  bottom: 0;
}

.section {
  padding: 24px;
}

.white-text {
  color: $white;
}

.black-text {
  color: $black;
}

.red-text {
  color: $red;
}

.yellow-text {
  color: $yellow;
}
.green-text {
  color: $green;
}

.semi-bold {
  font-weight: 600;
}

.full-width {
  width: 100%;
}
