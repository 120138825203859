@import 'variables';
@import 'global';

@import 'auth';
@import 'dashboard';
@import 'dashboard-drawer';
@import 'nav';

@import 'overview';
@import 'data-donation';
@import 'hp-modal';

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.purple {
  color: $purple;
}

.gray {
  color: $gray;
}

#root {
  display: flex;
  flex-direction: column;
}

// TODO: just temporary until we do more forms on portal
form {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  min-width: 320px;

  & > div {
    margin-top: 16px;
  }

  button {
    margin: 16px 0;
  }
}
