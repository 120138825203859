.data-donation {
  &__item {
    display: flex;
    padding: 16px;
  }

  &__key {
    font-weight: 600;
  }

  &__value {
    margin-left: 12px;
  }
}
