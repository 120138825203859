.dashboard {
  &__container {
    display: flex;
  }

  &__content {
    flex-grow: 1;
    padding: 1rem;
  }
}

.patient__page {

  &--delete {
    float: right;
    margin-top: 64px;
  }
  &--clickable-cell {
    cursor: pointer;

    &:hover {
      color: $dark-purple;
    }
  }

  &--function-list {
    margin: 20px 0;
  }

  &--dropdown {
    margin-top: 32px;
    min-width: 130px;
  }

  &--visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
    padding: 0;
    position: absolute;
  }

  &--button {
    margin-left: 12px;
  }
}

.overview-page {
  &__value-list {
    padding: 40px 0;
  }

  &__card {
    height: 150px;
    margin-right: 12px;
    margin-bottom: 20px;
    max-width: 150px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__new-reseach {
    &--form {
      padding: 30px 0;
    }

    &--input {
      margin-bottom: 20px;
    }

    &--success-message {
      color: $green;
    }

    &--error-message {
      color: $red;
    }
  }
}

.eventprofile {
  &__content {
    padding: 3px;

    &--zero-tp-padding {
      padding: 3px 16px;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }

  &__item {
    margin-top: 10px;
    padding-left: 10px;
  }
}

.card {
  border-radius: 4px;
  box-shadow: $shadow;
}

.researchers__page {
  &--total {
    padding: 30px;
  }

  &--button {
    margin-right: 10px;
    padding: 2px 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  &--registration {
    text-transform: capitalize;
  }
}
