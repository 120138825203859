$font-color: #393939;
$white: #fff;
$gray: #f9f9f9;
$purple: #c2a9c0;
$dark-purple: #bc9fb7;
$light-purple: #d6c5d3;
$black: #000;
$light-gray: #c4c4c4;
$dark-gray: #bcbec0;
$red: #ff5678;
$neon-green: #b6d43e;
$green: #008000;
$yellow: #fee12b;

$shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);